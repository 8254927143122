import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const SonnenlichtschaedenDerHautUndHautkrebsvorstufen = ({
    data,
    location,
    ...props
}) => {
    return (
        <Layout
            location={location}
            title="Sonnenlichtschäden der Haut und Hautkrebsvorstufen"
            noSlider
            {...props}
        >
            <p>In unseren ersten 20 bis 30 Lebensjahren leben wir. </p>
            <p>Ja, wir leben!</p>
            <p>
                Wir machen uns keine oder höchstens wenige, flüchtige Gedanken
                über das Leben nach dieser manchmal wilden Zeit.
            </p>
            <p>Sonne bedeutet Wohlfühlen.</p>
            <p>Zumindest vorerst.</p>
            <p>
                Erst Jahrzehnte später treten Hautveränderungen als Folge des
                wilden Lebens in den jungen Jahren auf.
            </p>
            <p>
                Neben dem schwarzen Hautkrebs, der von Zellen ausgeht, die
                dunkles Pigment bilden und einem braunen Leberfleck ähnlich
                sieht, bilden sich im Laufe der Zeit auch sogenannte weiße
                Hautkrebsformen. Diese besonders in den sonnenausgesetzten
                Arealen unseres Körpers, speziell unserem Gesicht.
            </p>
            <p>
                Ein frühes Erkennen und Behandeln bietet die Möglichkeit einer
                Heilung und unschöne Operationen lassen sich auf ein geringes
                Maß reduzieren.
            </p>
            <p>
                Besonders bei der Behandlung des sogenannten weißen Hautkrebses,
                der meistens im Gesicht, aber ebenso, wenn auch seltener, am
                Körper auftreten kann, bietet die Medizin wirklich vielfältige
                Behandlungsmöglichkeiten. Diese unterscheiden sich in der
                Erfolgsquote, der Anwendungsdauer, der Zeitdauer der Heilung und
                durch das zu erreichende gute kosmetische Ergebnis zum Abschluss
                der Behandlung.
            </p>
            <p>
                Die Entnahme einer kleinen Gewebeprobe und deren histologische
                Untersuchung hilft in vielen Fällen, den weißen Hautkrebs und
                seine Vorstufen zu klassifizieren und dadurch eine zielgenaue
                Wahl der Behandlungsform zu erarbeiten.
            </p>
            <p>Wir beraten umfassend, einfühlsam, offen und ehrlich.</p>
            <p>
                Wir erklären die verschiedenen Behandlungsformen mit den für die
                Entscheidungsfindung wichtigsten Eckdaten und erarbeiten mit
                Ihnen gemeinsam Ihren Behandlungsweg, sei es Laser,
                photodynamische Bestrahlung, chemische Salben, Kryotherapie oder
                auch die operative Therapie.
            </p>

            <Questions title="Sonnenlichtschäden der Haut und Hautkrebsvorstufen" />
        </Layout>
    );
};

export default SonnenlichtschaedenDerHautUndHautkrebsvorstufen;
